import React from 'react'
import { graphql } from 'gatsby'
import { Container } from 'theme-ui'
import Layout from '@solid-ui-layout/Layout'
import Seo from '@solid-ui-components/Seo'
import Divider from '@solid-ui-components/Divider'
import Header from '@solid-ui-blocks/Header/Block01'
import Tabs from '@solid-ui-components/Tabs'
import Hero from '@solid-ui-blocks/Hero/Block04'
import FeatureOne from '@solid-ui-blocks/FeaturesWithPhoto/Block02'
import FeatureTwo from '@solid-ui-blocks/FeaturesWithPhoto/Block05'
import FeatureThree from '@solid-ui-blocks/FeaturesWithPhoto/Block02'
import Features from '@solid-ui-blocks/Features/Block02'
import Screenshot from '@solid-ui-blocks/FeaturesWithPhoto/Block03'
import Process from '@solid-ui-blocks/Features/Block03'
import Pricing from '../components/Pricing/Block02'
import Faq from '@solid-ui-blocks/Faq/Block01'
import Blog from '@solid-ui-blocks/Blog/Block01'
import Footer from '@solid-ui-blocks/Footer/Block01'
import Stats from '@solid-ui-blocks/Stats/Block01'
import Companies from '@solid-ui-blocks/Companies/Block01'
import Testimonials from '@solid-ui-blocks/Testimonials/Block01'
import { normalizeBlockContentNodes } from '@blocks-helpers'
import theme from './_theme'
import styles from './_styles'
import { Script } from "gatsby"
import WithRecentPosts from '@solid-ui-blocks/WithRecentPosts'
import ModalWithTabs from '@solid-ui-blocks/Modal/Block01'
import Contact from '@solid-ui-blocks/CallToAction/Block02'
import FloatingLeadForm from '../components/LeadForm/LeadForm'
import CodeTabs from '../components/CodeTabs/CodeTabs'
import Calendly from '../components/Calendly/Calendly'
import PricingFree from '../components/PricingFree/PricingFree'
import UseCases from '@solid-ui-blocks/Features/Block02'

const IndexPage = props => {
  const { allBlockContent } = props.data
  const content = normalizeBlockContentNodes(allBlockContent?.nodes)

  return (
    <Layout theme={theme} {...props}>
      <Seo title='Innovate Faster with Feature Flags - Toggly'
      description='Toggly makes it easier for software developers to build better software faster by making it easy to test, release, and measure features without the risk of downtime or other production impacts.'
      keywords={['feature flags, software development']}
      author='opsAI LLC'
      category='Software Development'
      thumbnail='https://toggly.io/cover.png'
      siteUrl='https://toggly.io'
      locale='en_US'
      />

      <ModalWithTabs content={content['contact']} />
      {/* Blocks */}
      <Header content={content['header-light']} menuJustify='space-between' />
      <Divider space='5' />
      <Container variant='full' sx={styles.heroContainer}>
        <Hero content={content['hero']} reverse />
      </Container>
      <Divider space='5' />
      <Divider space='5' />

      <Calendly />
      <Divider space='5' />
      <Divider space='5' />

      <Container variant='wide' sx={styles.tabsContainer}>
        <Tabs space={4}>
          <FeatureTwo content={content['tab-feature-one']} />
          <FeatureTwo content={content['tab-feature-two']} />
          <FeatureTwo content={content['tab-feature-three']} />
        </Tabs>
      </Container>
      <Divider space='5' />

      <Container variant="wide" sx={styles.codeTabsContainer}>
        <CodeTabs content={content['code-examples']} />
      </Container>
      <Divider space='5' />
      <Divider space='5' />

      <Container variant="wide">
        <UseCases content={content['use-cases']} />
      </Container>
      <Divider space='2' />

      {/* <Calendly /> */}
      {/* <Divider space='5' />
      <Container variant='full' sx={styles.featuresContainer}>
        <Features content={content['features']} />
      </Container> */}
      {/* <Divider space='5' />
      <Container variant='wide' sx={styles.tabsContainer}>
        <Tabs space={3} variant='dots' position='bottom' arrows>
          <Screenshot content={content['screenshot-one']} />
          <Screenshot content={content['screenshot-two']} />
        </Tabs>
      </Container>
      <Divider space='5' />
      <Divider space='5' />
      <Divider space='5' />
      <Process content={content['process']} />
      
      {/* <Divider space='5' />
      <Container variant='full' sx={styles.socialProofContainer}>
        <Divider space='5' />
        <Divider space='5' />
        <Container variant='narrow'>
          <Stats content={content['stats']} />
          <Divider space='5' />
        </Container>
        <Testimonials content={content['testimonials']} />
        <Divider space='5' />
      </Container>
      <Divider space='5' /> */}
{/* 
      <Divider space='5' />
      <Contact content={content['cta']} /> */}

      <Divider space='5' />
      <Pricing content={content['pricing']} />
      <Divider space='2' />
      <Calendly />
      <Divider space='5' />
      {/* <Companies content={content['companies']} />
      <Divider space='5' /> */}
      <Container variant='narrow'>
        <Faq content={content['faq']} />
      </Container>
      <Divider space='5' />
      <Divider space='5' />
      <WithRecentPosts>
        <Blog content={content['latest-blogs']} />
      </WithRecentPosts>
      <Divider space='5' />
      <Footer content={content['footer']} />

      {/* <FloatingLeadForm />

      <Script id="hs-script-loader" async defer src="//js.hs-scripts.com/7346852.js" /> */}

    <link href="https://assets.calendly.com/assets/external/widget.css" rel="stylesheet" type="text/css"></link>
    <Script src="https://assets.calendly.com/assets/external/widget.js" />

    </Layout>
  )
}

export const query = graphql`
  query homepageSaasV2BlockContent {
    allBlockContent(filter: { page: { in: ["homepage/saas-v2", "shared"] } }) {
      nodes {
        ...BlockContent
      }
    }
  }
`
export default IndexPage
